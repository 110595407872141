import type { IRootState } from '../types';
import { createSelector } from 'reselect';
import { partition, sortBy } from 'lodash';

import { Event, Status } from '@wix/ambassador-events-v3-event/types';

export const selectEvents = (state: IRootState) => state.events.events;

export const selectUpcomingAndPastEvents = createSelector(
  selectEvents,
  (events) => {
    const [upcoming, past] = splitByUpcomingAndPast(events);

    return [
      upcoming,
      sortBy(past, ['event', 'scheduling', 'config', 'startDate']),
    ];
  },
);

function splitByUpcomingAndPast(
  events: Event[],
): [upcoming: Event[], past: Event[]] {
  const [upcoming, past] = partition(
    events,
    (event) =>
      event.status === Status.STARTED || event.status === Status.UPCOMING,
  );

  return [upcoming, past];
}

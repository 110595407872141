import React, { useEffect } from 'react';
import cls from 'classnames';

import {
  TextField as TextFieldTPA,
  TextFieldProps,
  TextFieldTheme,
} from 'wix-ui-tpa/cssVars';

import classes from './TextField.scss';

interface ITextFieldProps extends Omit<TextFieldProps, 'error' | 'theme'> {
  wired?: boolean;
  error?: boolean | string;
  theme?: 'line' | 'box';
}

export function TextField(props: ITextFieldProps) {
  const {
    wired,
    value: _,
    className,
    theme,
    error,
    onChange,
    errorMessage,
    ...rest
  } = props;

  const [value, setValue] = React.useState(props.value || '');

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  return (
    <TextFieldTPA
      value={value}
      newErrorMessage
      onChange={handleChange}
      theme={theme as TextFieldTheme}
      className={cls(classes.root, className, {
        [classes.wired]: wired,
        [classes.empty]: !value,
      })}
      inputClassName={classes.input}
      error={typeof error === 'string' ? error !== undefined : !!error}
      errorMessage={typeof error === 'string' ? error : errorMessage}
      {...rest}
    />
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    onChange?.(event);
  }
}

TextField.displayName = 'wui/TextField';

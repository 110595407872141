import React from 'react';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import type { Alignment, Direction, Spacing, VerticalAlignment } from './types';

import SPACINGS from '../theme/spacing.scss';
import { classes as stClasses, st } from './Box.st.css';

export interface IBoxProps extends TPAComponentProps {
  style?: React.CSSProperties;
  children?: React.ReactNode | React.ReactNode[];

  flex?: boolean;
  inline?: boolean;
  wrap?: boolean;
  grow?: boolean;
  truncate?: boolean;
  scrollable?: boolean;

  direction?: Direction;
  align?: Alignment;
  verticalAlign?: VerticalAlignment;

  gap?: Spacing | number | string;

  padding?: Spacing | number | string;
  paddingTop?: Spacing | number | string;
  paddingLeft?: Spacing | number | string;
  paddingRight?: Spacing | number | string;
  paddingBottom?: Spacing | number | string;

  margin?: Spacing | number | string;
  marginTop?: Spacing | number | string;
  marginLeft?: Spacing | number | string;
  marginRight?: Spacing | number | string;
  marginBottom?: Spacing | number | string;

  width?: string | number;
  height?: string | number;
}

// eslint-disable-next-line react/display-name
export const Box = React.forwardRef(
  (
    props: OverridableComponentProps<IBoxProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const {
      style: _,
      as = 'div',
      inline,
      wrap,
      grow,
      truncate,
      scrollable,
      direction,
      align,
      verticalAlign,
      width,
      height,
      className,
      flex,
      gap,
      padding,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,
      margin,
      marginTop,
      marginLeft,
      marginRight,
      marginBottom,
      ...rest
    } = props;

    const style = Object.fromEntries(
      Object.entries(props)
        .filter(([key]) =>
          [
            'gap',
            'paddingTop',
            'paddingLeft',
            'paddingRight',
            'paddingBottom',
            'padding',
            'marginTop',
            'marginLeft',
            'marginRight',
            'marginBottom',
            'margin',
          ].includes(key),
        )
        .map(([key, value]) => {
          return [key, getSpacingValue(value as string)];
        }),
    );

    const Element = as;

    return (
      <Element
        ref={ref}
        style={{
          width,
          height,
          ...style,
          ...props.style,
        }}
        className={st(
          stClasses.root,
          {
            simple: !flex,
            scrollable: scrollable as boolean,
            inline: inline as boolean,
            wrap: wrap as boolean,
            grow: grow as boolean,
            truncate: truncate as boolean,
            direction: direction as Direction,
            alignItems: align as Alignment,
            justifyContent: verticalAlign as VerticalAlignment,
          },
          className,
        )}
        {...rest}
      >
        {props.children as any}
      </Element>
    );
  },
) as OverridableComponent<IBoxProps, 'div'>;

function getSpacingValue(value: number | string = '') {
  if (typeof value === 'number') {
    return value;
  }

  return value
    .split(' ')
    .map((spacing) => SPACINGS[spacing] || spacing)
    .filter((value) => value !== undefined)
    .join(' ');
}

Box.displayName = 'wui/Box';
Box.defaultProps = {
  flex: true,
  direction: 'horizontal',
};

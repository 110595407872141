import { createEntityAdapter } from '@reduxjs/toolkit';

import type { IGroupRequest } from 'api/group-requests/types';

import { IRootState } from '../types';

export const groupRequestsAdapter = createEntityAdapter<IGroupRequest>({
  selectId: (item) => item.id as string,
  sortComparer: (a, b) => {
    if (a.group.createdDate! > b.group.createdDate!) {
      return -1;
    }

    if (a.group.createdDate! < b.group.createdDate!) {
      return 1;
    }

    return 0;
  },
});

export const selectors = groupRequestsAdapter.getSelectors<IRootState>(
  (state) => state.groupRequests,
);

import React, { useMemo } from 'react';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';

interface IPortalContextValue {
  host: IHostProps;
}

export const PortalContext = React.createContext<IPortalContextValue>({
  host: { id: 'storybook' },
} as IPortalContextValue);

interface IPortalProviderProps extends IPortalContextValue {
  children: React.ReactNode;
}

export function PortalProvider(props: IPortalProviderProps) {
  const { host, children } = props;

  const value = useMemo(() => ({ host }), [host.id]);

  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
}

PortalProvider.displayName = 'PortalProvider';

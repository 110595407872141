import React from 'react';
import cls from 'classnames';
import { useStyles } from '@wix/tpa-settings/react';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import * as paper from 'settings/wui/paper';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import classes from './Paper.scss';

export interface IPaperProps extends TPAComponentProps {
  children?: React.ReactNode;

  bw?: boolean;
  shadow?: boolean;
  scrollable?: boolean;
  sideBorders?: boolean;
  topBottomBorders?: boolean;
}

// eslint-disable-next-line react/display-name
export const Paper = React.forwardRef(
  (
    props: OverridableComponentProps<IPaperProps, 'div'>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const {
      as = 'div',
      children,
      className,
      shadow = true,
      sideBorders = true,
      topBottomBorders = true,
      scrollable,
      bw,
      ...rest
    } = props;

    const styles = useStyles();

    const Element = as;

    return (
      <Element
        ref={ref}
        data-monochrome={bw}
        data-shadow={shadow && styles.get(paper.styles.paperShadow)}
        data-side-borders={sideBorders}
        data-top-bottom-borders={topBottomBorders}
        data-scrollable={scrollable}
        className={cls(classes.root, className, {
          [classes.bw]: bw,
          [classes.withShadow]: shadow && styles.get(paper.styles.paperShadow),
          [classes.sideBorders]: sideBorders,
          [classes.topBottomBorders]: topBottomBorders,
          [classes.scrollable]: scrollable,
        })}
        {...rest}
      >
        {props.children}
      </Element>
    );
  },
) as unknown as OverridableComponent<IPaperProps, 'div'>;

Paper.displayName = 'wui/Paper';

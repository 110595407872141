import React from 'react';
import cls from 'classnames';

import {
  Tabs as TPATabs,
  TabsAlignment,
  type TabsProps,
  TabsSkin,
  TabsVariant,
} from 'wix-ui-tpa/cssVars';

import classes from './Tabs.scss';
import { classes as stClasses } from './Tabs.st.css';

export interface ITabsProps<T>
  extends Omit<
    TabsProps,
    'activeTabIndex' | 'onTabClick' | 'skin' | 'variant' | 'alignment'
  > {
  value?: T;
  skin?: keyof typeof TabsSkin;
  variant?: keyof typeof TabsVariant;
  alignment?: keyof typeof TabsAlignment;

  onChange: (id: T) => void;
}

export function Tabs<T extends string>(props: ITabsProps<T>) {
  const { value, onChange, className, skin, variant, alignment, ...rest } =
    props;

  const [activeTabIndex, setActiveTabIndex] = React.useState(
    props.items.findIndex((item) => item.id === value),
  );

  React.useEffect(() => {
    const index = props.items.findIndex((item) => item.id === value);

    setActiveTabIndex(index);
  }, [value, props.items.map((item) => item.id).join()]);

  return (
    <TPATabs
      skin={skin as TabsSkin}
      variant={variant as TabsVariant}
      alignment={alignment as TabsAlignment}
      onTabClick={handleTabClick}
      activeTabIndex={activeTabIndex}
      className={cls(classes.root, stClasses.root, className)}
      {...rest}
    />
  );

  function handleTabClick(index: number) {
    const active = props.items[index];

    setActiveTabIndex(index);

    return onChange(active.id as T);
  }
}

Tabs.displayName = 'wui/Tabs';

export type { TabItem } from 'wix-ui-tpa/cssVars';

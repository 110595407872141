import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  useEnvironment,
  useExperiments,
  WidgetEnvironmentProvider,
} from '@wix/yoshi-flow-editor';

import {
  TPAComponentsProvider,
  useTPAComponentsContext,
} from 'wix-ui-tpa/cssVars';

interface IProps {
  children: React.ReactNode;
}

export function FakeResponsiveProvider(props: IProps) {
  const { children } = props;

  const environment = useEnvironment();
  const { experiments } = useExperiments();
  const tpaComponents = useTPAComponentsContext();

  const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

  const widgetEnvironment = useMemo(
    () => ({ ...environment, isMobile }),
    [isMobile],
  );

  const tpaComponentsEnvironment = useMemo(
    () => ({ ...tpaComponents, mobile: isMobile }),
    [isMobile],
  );

  if (
    !environment.isViewer ||
    !environment.isCssPerBreakpoint ||
    !experiments.enabled('specs.groups.FakeResponsiveProvider')
  ) {
    return children;
  }

  return (
    <WidgetEnvironmentProvider value={widgetEnvironment}>
      <TPAComponentsProvider value={tpaComponentsEnvironment}>
        {children}
      </TPAComponentsProvider>
    </WidgetEnvironmentProvider>
  );
}

FakeResponsiveProvider.displayName = 'FakeResponsiveProvider';

import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './ButtonGroup.scss';

interface IButtonGroupProps extends TPAComponentProps {
  as?: React.ElementType;
  orientation?: 'horizontal' | 'vertical';

  children: React.ReactNode;
}

/**
 * @deprecated Use `Stack` or `Box` instead
 */
export function ButtonGroup(props: IButtonGroupProps) {
  const Element = props.as as React.ElementType;
  return (
    <Element
      data-hook={props['data-hook']}
      data-orientation={props.orientation}
      className={cls(classes.root, props.className, {
        [classes.vertical]: props.orientation === 'vertical',
        [classes.horizontal]: props.orientation === 'horizontal',
      })}
    >
      {props.children}
    </Element>
  );
}

ButtonGroup.displayName = 'wui/ButtonGroup';
ButtonGroup.defaultProps = {
  as: 'div',
  orientation: 'horizontal',
};

import React from 'react';
import cls from 'classnames';

import type {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';
import { Stack } from '../Stack';

import classes from './List.scss';

interface IListProps extends React.ComponentProps<typeof Stack> {
  children: React.ReactNode | Iterable<React.ReactNode>;

  disablePadding?: boolean;
  disableGutters?: boolean;
}

// eslint-disable-next-line react/display-name
export const List = React.forwardRef(
  (props: OverridableComponentProps<IListProps, 'ul'>, ref) => {
    const { disablePadding, disableGutters, className, ...rest } = props;

    return (
      <Stack
        as="ul"
        ref={ref}
        direction="vertical"
        data-padding={!disablePadding}
        data-gutters={!disableGutters}
        className={cls(classes.root, className, {
          [classes.disablePadding]: disablePadding,
          [classes.disableGutters]: disableGutters,
        })}
        {...rest}
      >
        {props.children}
      </Stack>
    );
  },
) as OverridableComponent<IListProps, 'ul'>;

List.displayName = 'wui/List';
List.defaultProps = {
  gap: 'SP0',
};

import React from 'react';

import {
  ImageHoverEffectOptions,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
  WowImage,
  WowImageProps,
} from 'wix-ui-tpa/cssVars';

interface IProps
  extends Omit<WowImageProps, 'resize' | 'hoverEffect' | 'loadingBehavior'> {
  resize?: keyof typeof ImageResizeOptions;
  hoverEffect?: keyof typeof ImageHoverEffectOptions;
  loadingBehavior?: keyof typeof ImageLoadingBehaviorOptions;
}

export function Media(props: IProps) {
  const { resize, hoverEffect, loadingBehavior, ...rest } = props;

  return (
    <WowImage
      resize={resize as ImageResizeOptions}
      hoverEffect={hoverEffect as ImageHoverEffectOptions}
      loadingBehavior={loadingBehavior as ImageLoadingBehaviorOptions}
      {...rest}
    />
  );
}

Media.displayName = 'wui/Media';

export type { ImageFocalPoint } from 'wix-ui-tpa/cssVars';
